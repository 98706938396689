<template>
  <div class="row">
    <div class="d-print-none col-md-auto">
      <!--begin::Menu-->
      <div class="card card-flush d-none d-lg-flex w-lg-300px position-sticky" :style="{ top: '20px' }">
        <scrollactive
          class="nav nav-flush menu menu-column menu-title-gray-800 menu-state-primary bg-white rounded d-none d-lg-block py-4"
          active-class="active"
          :modify-url="false"
        >
          <div v-for="item in sideMenu" :key="item.title" class="menu-item d-flex mb-4">
            <div
              v-show="(proposalStatus !== 'Sent to Sign' && proposalStatus !== 'Signed') || !signedManualy"
              class="form-check form-switch form-check-custom form-check-solid me-2 me-lg-4"
            >
              <input
                v-model="item.control"
                class="form-check-input h-20px w-30px side-menu-checkbox"
                type="checkbox"
                @change="item.onChange"
              />
            </div>
            <a :href="`#${item.hash}`" class="scrollactive-item menu-link nav-link align-items-start p-0">
              <span class="menu-title">{{ item.title }}</span>
            </a>
          </div>
        </scrollactive>
        <div>
          <slot name="sidebar" />
        </div>
        <div v-if="!compareToggles && !isLoading" class="d-flex align-items-center">
          <px-btn size="sm" color="secondary" extended-classes="me-2" @click.native="onCancel">Cancel</px-btn>
          <px-btn
            extended-classes="btn-sm"
            :in-process="saveInProcess"
            :disabled="saveInProcess"
            @click.native="onSave"
          >
            Save
          </px-btn>
        </div>
      </div>
      <!--end::Menu-->
    </div>

    <div class="col-md">
      <div v-if="proposalInfo" :class="['card shadow-sm', { 'card-fullscreen': isFullScreen }]">
        <slot name="before-header" />

        <div class="card-header d-block bg-light p-12 border-0">
          <div class="row justify-content-between align-items-center mb-10">
            <div class="col-md-5 col-xl-4 d-flex mb-6 mb-md-0">
              <img class="img-fluid mh-70px mh-xxxl-125px" :src="proposalInfo.logo" alt="RSG Logo" />
            </div>

            <div class="col-md-5 col-xl-6 text-md-end">
              <h3 class="fw-bold display-7">{{ scopeName }} {{ state || 'Proposal' }}</h3>
              <h4 class="fw-bold">
                {{ number || "Number isn't set" }}
              </h4>
            </div>

            <slot name="header-end" />
          </div>

          <div class="row justify-content-between align-items-end">
            <div class="col-md-5 col-xl-4 mb-6 mb-md-0">
              <h4 class="fw-bold">Customer</h4>
              <address v-if="projectInfo" class="mb-0">
                {{ this.status === 'Signed' ? proposalInfo.subject_first_name : projectInfo.first_name }}
                {{ this.status === 'Signed' ? proposalInfo.subject_last_name : projectInfo.last_name }}
                <br />
                <!--this.status === 'Signed' ? proposalInfo.subject_postal : projectInfo.postal,-->
                {{ this.status === 'Signed' ? proposalInfo.subject_address_line_1 : projectInfo.address_line_1 }}
                <br />
                {{
                  separateStringWithComma([
                    this.status === 'Signed' ? proposalInfo.subject_city : projectInfo.city,
                    this.status === 'Signed' ? proposalInfo.subject_province : projectInfo.province,
                  ])
                }}
                <br />
                {{ this.status === 'Signed' ? proposalInfo.subject_phone : projectInfo.phone }}
                <br />
                {{ this.status === 'Signed' ? proposalInfo.subject_email : projectInfo.email }}
              </address>
            </div>

            <div class="col-md-5 col-xl-3 text-md-end">
              <div class="d-flex justify-content-between">
                <span class="me-4">Proposal number:</span>
                <strong>{{ number || "Number isn't set" }}</strong>
              </div>
              <div class="d-flex justify-content-between">
                <span class="me-4">Version:</span> <strong class="fw-normal">v{{ proposalInfo.version || 1 }}</strong>
              </div>
              <div class="d-flex justify-content-between">
                <span class="me-4">Date:</span>
                <strong class="fw-normal">{{ estimateDate | moment('MMMM D, YYYY') }}</strong>
              </div>
              <div class="d-flex justify-content-between">
                <span class="me-4">Project Manager:</span>
                <strong class="fw-normal">{{ manager }}</strong>
              </div>
              <div class="d-flex justify-content-between">
                <span class="me-4">Currency:</span> <strong class="fw-normal">CAD</strong>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Body-->
        <div v-if="checkedToggles" class="card-body p-8">
          <div v-if="state !== 'Change Order' && showIntroduction" id="introduction" class="mb-14">
            <h2 class="fw-bold mb-4">Introduction</h2>

            <div
              v-if="proposalInfo.introduction"
              class="format-no-space"
              v-html="normalizeString(proposalInfo.introduction)"
            />
            <px-placeholder v-else />
          </div>
          <div
            v-if="state !== 'Change Order' && proposalInfo.scope_type === 'Swimming Pool' && showSpecifications"
            id="specifications"
            class="mb-14"
          >
            <h2 class="fw-bold mb-4">Specifications</h2>

            <div v-if="constructions.length && proposalInfo.construction" class="row">
              <div class="col-sm-6 col-lg-3 mb-6">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/pool.png"
                      alt="Construction"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Construction"
                    />
                  </div>
                  <div
                    class="card-body p-4"
                    v-html="
                      replaceBreakWithBrTag(
                        constructions.find((el) => el.title === proposalInfo.construction).description
                      )
                    "
                  />
                  <!--                  <div-->
                  <!--                      class="card-body p-4"-->
                  <!--                      v-html="-->
                  <!--                      replaceBreakWithBrTag(-->
                  <!--                        proposalInfo.fiberglass_details ||-->
                  <!--                          constructions.find((el) => el.title === proposalInfo.construction).description-->
                  <!--                      )-->
                  <!--                    "-->
                  <!--                  />-->
                </div>
              </div>

              <div class="col-sm-6 col-lg-3 mb-6">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/size.png"
                      alt="Size"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Size"
                    />
                  </div>
                  <div class="card-body p-4">
                    <div class="mb-2">
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(proposalInfo.width).ft }} ft.
                        {{ transformToFtAndIn(proposalInfo.width).in }} in.
                      </h4>
                      <div class="text-muted">width</div>
                    </div>

                    <div>
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(proposalInfo.length).ft }} ft.
                        {{ transformToFtAndIn(proposalInfo.length).in }} in.
                      </h4>
                      <div class="text-muted">length</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3 mb-6">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/square.png"
                      alt="Shape"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Shape"
                    />
                  </div>
                  <div class="card-body p-4">
                    <p v-if="isFiberglassCustom" class="mb-2">Custom</p>
                    <p v-if="!isFiberglassCustom && proposalInfo.shape_name" class="mb-2">
                      {{ proposalInfo.shape_name }}
                    </p>
                    <p class="mb-0">
                      {{ proposalInfo.shape === 'Custom' ? proposalInfo.custom_shape_text : proposalInfo.shape }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-lg-3 mb-6">
                <div class="card bg-light h-100 lh-sm">
                  <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                    <img
                      src="/assets/media/icons/custom/deep.png"
                      alt="Approx. water depths"
                      width="30"
                      height="30"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Approx. water depths"
                    />
                  </div>
                  <div class="card-body p-4">
                    <div class="mb-2">
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(proposalInfo.shallow).ft }} ft.
                        {{ transformToFtAndIn(proposalInfo.shallow).in }} in.
                      </h4>
                      <div class="text-muted">shallow</div>
                    </div>

                    <div>
                      <h4 class="h5 mb-0">
                        {{ transformToFtAndIn(proposalInfo.deepend).ft }} ft.
                        {{ transformToFtAndIn(proposalInfo.deepend).in }} in.
                      </h4>
                      <div class="text-muted">deep</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <px-placeholder v-else />

            <p v-html="replaceBreakWithBrTag(proposalInfo.construction_details)" />
          </div>
          <div
            v-if="
              state !== 'Change Order' &&
              proposalInfo.scope_type === 'Swimming Pool' &&
              construction !== 'Renovate' &&
              showEquipments
            "
            id="equipment"
            class="mb-14"
          >
            <h2 class="fw-bold mb-4">Standard Equipment</h2>
            <div v-if="equipment.length && proposalInfo.construction !== 'Renovate'">
              <p>
                Your <span class="text-uppercase">{{ proposalInfo.construction }}</span>
                AquaSpa pool includes the following:
              </p>
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table table-striped mb-0">
                  <!--begin::Thead-->
                  <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                    <tr class="border-bottom">
                      <th class="w-200px">Item</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <!--end::Thead-->
                  <!--begin::Tbody-->
                  <tbody>
                    <tr v-for="equipmentItem of equipment" :key="equipmentItem.id">
                      <td class="fw-semi-bold align-middle text-lowercase first-letter-capitalize">
                        {{ equipmentItem.attributes.item }}
                      </td>
                      <td>{{ equipmentItem.attributes.description }}</td>
                    </tr>
                  </tbody>
                  <!--end::Tbody-->
                </table>
                <!--end::Table-->
              </div>
            </div>
            <px-placeholder
              v-else
              :text="
                proposalInfo.construction === 'Renovate'
                  ? 'Equipment is not intended for renovation'
                  : 'No data to show'
              "
            />
          </div>
          <div
            v-if="showPricing && checkIfHasPricingItems(pricingSections, 'pricing_items')"
            id="pricing"
            class="mb-14"
          >
            <h2 class="fw-bold mb-4">Pricing</h2>
            <div v-for="section in pricingSections" :key="section.id">
              <div v-if="section.attributes.pricing_items.data.length">
                <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">
                  {{ section.attributes.name || 'Unnamed' }}
                </h3>
                <div v-if="section.attributes.pricing_items.data.length" class="mb-12">
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table table-striped mb-0">
                      <!--begin::Thead-->
                      <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                        <tr class="border-bottom">
                          <th class="w-200px">Item</th>
                          <th>Description</th>
                          <th class="w-50px">Qty</th>
                          <th class="w-100px">Unit</th>
                          <th class="w-150px text-end">Price</th>
                        </tr>
                      </thead>
                      <!--end::Thead-->
                      <!--begin::Tbody-->
                      <tbody>
                        <tr v-for="pricingItem in section.attributes.pricing_items.data" :key="pricingItem.id">
                          <td class="fw-semi-bold">
                            {{
                              pricingItem.attributes.name ||
                              (pricingItem.attributes.pricing_item_template.data
                                ? pricingItem.attributes.pricing_item_template.data.attributes.name
                                : '')
                            }}
                          </td>
                          <td>
                            <div v-html="replaceBreakWithBrTag(pricingItem.attributes.description)" />
                            <div
                              v-if="pricingItem.attributes.show_client_note && pricingItem.attributes.note"
                              class="d-flex notice bg-light-warning rounded p-3 mt-4 align-items-center"
                            >
                              <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                              </span>
                              <span>
                                {{ pricingItem.attributes.note }}
                              </span>
                            </div>
                            <div
                              v-if="
                                pricingItem.attributes.show_internal_note &&
                                pricingItem.attributes.internal_note &&
                                section.attributes.show_notes
                              "
                              class="d-flex notice bg-light-danger rounded p-3 mt-4 align-items-center"
                            >
                              <span class="svg-icon svg-icon-3x svg-icon-danger me-4">
                                <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                              </span>
                              <span>
                                {{ pricingItem.attributes.internal_note }}
                              </span>
                            </div>
                          </td>
                          <td>{{ pricingItem.attributes.total_quantity }}</td>
                          <td>{{ pricingItem.attributes.unit }}</td>
                          <td class="text-end">
                            <span v-if="pricingItem.attributes.price_format === 'Default'">
                              {{
                                $n(
                                  pricingItem.attributes.total_quantity * pricingItem.attributes.price,
                                  'currency',
                                  'en-CA'
                                )
                              }}
                            </span>
                            <span v-else>
                              {{ pricingItem.attributes.price_format }}
                            </span>
                          </td>
                        </tr>
                        <tr class="bg-light-primary">
                          <td class="text-primary text-end" colspan="5">
                            <span class="text-uppercase text-ls fs-9">SubTotal:</span>
                            <strong class="fw-bold fs-5">
                              {{ $n(totalPricingItems(section.attributes.pricing_items.data), 'currency', 'en-CA') }}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                      <!--end::Tbody-->
                    </table>
                    <!--end::Table-->
                  </div>

                  <div
                    v-if="section.attributes.show_important_notice && section.attributes.note"
                    class="d-flex notice bg-light-warning rounded p-3 mt-4"
                  >
                    <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                      <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                    </span>
                    <span class="align-self-center">
                      {{ section.attributes.note }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              proposalInfo.scope_type === 'Landscaping' &&
              showPlantings &&
              checkIfHasPricingItems(plantingSections, 'planting_items')
            "
            id="planting"
            class="mb-14"
          >
            <h2 class="fw-bold mb-4">Planting</h2>
            <div v-for="section in plantingSections" :key="section.id">
              <div v-if="section.attributes.planting_items.data.length">
                <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">
                  {{ section.attributes.name || 'Unnamed' }}
                </h3>
                <div v-if="section.attributes.planting_items.data.length" class="mb-12">
                  <div class="table-responsive">
                    <!--begin::Table-->
                    <table class="table table-striped mb-0">
                      <!--begin::Thead-->
                      <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                        <tr class="border-bottom">
                          <th class="w-150px">Category</th>
                          <th class="w-150px">Item</th>
                          <th>Description</th>
                          <th class="w-80px">Size</th>
                          <!--                          <th class="w-100px">Cost</th>-->
                          <!--                          <th class="w-50px">X</th>-->
                          <th class="w-100px">Qty</th>
                          <th class="w-100px text-end">Price</th>
                        </tr>
                      </thead>
                      <!--end::Thead-->
                      <!--begin::Tbody-->
                      <tbody>
                        <tr v-for="pricingItem in section.attributes.planting_items.data" :key="pricingItem.id">
                          <td>
                            {{
                              pricingItem.attributes.planting_item_template.data
                                ? pricingItem.attributes.planting_item_template.data.attributes.category
                                : ''
                            }}
                          </td>
                          <td>
                            <div v-if="pricingItem.attributes.planting_item_template.data">
                              <strong>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.english_name }}
                              </strong>
                              <em class="d-block">
                                {{ pricingItem.attributes.planting_item_template.data.attributes.botanical_name }}
                              </em>
                            </div>
                          </td>
                          <td>
                            <div v-html="replaceBreakWithBrTag(pricingItem.attributes.description)" />
                            <div
                              v-if="pricingItem.attributes.planting_item_template.data"
                              class="row align-items-center small py-2 gx-4"
                            >
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.full_sun"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon">
                                  <inline-svg src="/assets/media/icons/custom/full-sun.svg" />
                                </span>
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.partial_sun"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon">
                                  <inline-svg src="/assets/media/icons/custom/partial-sun.svg" />
                                </span>
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.full_shade"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon">
                                  <inline-svg src="/assets/media/icons/custom/full-shade.svg" />
                                </span>
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.native"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon">
                                  <inline-svg src="/assets/media/icons/custom/native.svg" stroke="#a1a5b7" />
                                </span>
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.attracts_birds"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon">
                                  <inline-svg src="/assets/media/icons/custom/attractive-birds.svg" stroke="#a1a5b7" />
                                </span>
                              </div>

                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.height"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon me-1">
                                  <inline-svg src="/assets/media/icons/custom/height.svg" />
                                </span>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.height }}
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.bloom"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon me-1">
                                  <inline-svg src="/assets/media/icons/custom/bloom-color.svg" />
                                </span>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.bloom }}
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.spread"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon me-1">
                                  <inline-svg src="/assets/media/icons/custom/spread.svg" />
                                </span>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.spread }}
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.foliage"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon me-1">
                                  <inline-svg src="/assets/media/icons/custom/foliage-value.svg" />
                                </span>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.foliage }}
                              </div>
                              <div
                                v-if="pricingItem.attributes.planting_item_template.data.attributes.shape"
                                class="col-auto text-nowrap mb-2"
                              >
                                <span class="svg-icon me-1">
                                  <inline-svg src="/assets/media/icons/custom/shape.svg" />
                                </span>
                                {{ pricingItem.attributes.planting_item_template.data.attributes.shape }}
                              </div>
                            </div>
                            <div
                              v-if="pricingItem.attributes.show_client_note && pricingItem.attributes.note"
                              class="d-flex notice bg-light-warning rounded p-3 mt-4 align-items-center"
                            >
                              <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                              </span>
                              <span>
                                {{
                                  pricingItem.attributes.note ||
                                  (pricingItem.attributes.planting_item_template.data
                                    ? pricingItem.attributes.planting_item_template.data.attributes.note
                                    : '')
                                }}
                              </span>
                            </div>
                            <div
                              v-if="pricingItem.attributes.show_internal_note && pricingItem.attributes.internal_note"
                              class="d-flex notice bg-light-danger rounded p-3 mt-4 align-items-center"
                            >
                              <span class="svg-icon svg-icon-3x svg-icon-danger me-4">
                                <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                              </span>
                              <span class="align-self-center">
                                {{
                                  pricingItem.attributes.internal_note ||
                                  pricingItem.attributes.planting_item_template.data.attributes.internal_note
                                }}
                              </span>
                            </div>
                          </td>
                          <td>
                            {{
                              pricingItem.attributes.size ||
                              `${pricingItem.attributes.planting_item_template.data.attributes.size_digit} ${
                                pricingItem.attributes.planting_item_template.data.attributes.size_unit || 'cm'
                              }`
                            }}
                          </td>
                          <!--                          <td>-->
                          <!--                            {{ pricingItem.attributes.cost }}-->
                          <!--                          </td>-->
                          <!--                          <td>-->
                          <!--                            {{ pricingItem.attributes.multiplier || 1 }}-->
                          <!--                          </td>-->
                          <td>{{ pricingItem.attributes.quantity }}</td>
                          <td class="text-end">
                            <span v-if="pricingItem.attributes.price_format === 'Default'">
                              {{
                                $n(pricingItem.attributes.quantity * pricingItem.attributes.price, 'currency', 'en-CA')
                              }}
                            </span>
                            <span v-else>
                              {{ pricingItem.attributes.price_format }}
                            </span>
                          </td>
                        </tr>
                        <tr class="bg-light-primary">
                          <td class="text-primary text-end" colspan="6">
                            <span class="text-uppercase text-ls fs-9">SubTotal:</span>
                            <strong class="fw-bold fs-5">
                              {{ $n(totalPlantingItems(section.attributes.planting_items.data), 'currency', 'en-CA') }}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                      <!--end::Tbody-->
                    </table>
                    <!--end::Table-->
                  </div>

                  <div
                    v-if="section.attributes.show_important_notice && section.attributes.note"
                    class="d-flex notice bg-light-warning rounded p-3 mt-4"
                  >
                    <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                      <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                    </span>
                    <span class="align-self-center">
                      {{ section.attributes.note }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showSpecialNotes && proposalInfo.special_notes" id="notes" class="mb-14">
            <h2 class="fw-bold mb-4">Notes</h2>
            <div v-html="normalizeString(proposalInfo.special_notes)" />
          </div>
          <div v-if="state !== 'Change Order' && showInclusions" id="considerations" class="mb-14">
            <h2 class="fw-bold mb-4">Considerations</h2>
            <div class="table-responsive">
              <!--begin::Table-->
              <table :class="['table mb-0', { 'table-striped': inclusions.length }]">
                <!--begin::Thead-->
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                  <tr class="border-bottom">
                    <th class="w-200px">Item</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <!--end::Thead-->
                <!--begin::Tbody-->
                <tbody v-if="inclusions.length">
                  <tr v-for="inclusion in inclusions" :key="inclusion.id">
                    <td class="fw-semi-bold align-middle">{{ inclusion.attributes.name }}</td>
                    <td>{{ inclusion.attributes.description }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="2">
                      <px-placeholder />
                    </td>
                  </tr>
                </tbody>
                <!--end::Tbody-->
              </table>
              <!--end::Table-->
            </div>
          </div>
          <div v-if="showPaymentDetails" id="payment-details" class="mb-14">
            <h2 class="fw-bold mb-4">Payment Details</h2>
            <div class="row mb-6 justify-content-between">
              <div class="col-md-5 col-xl-4 mb-6">
                <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Total Summary</h3>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="me-4">Subtotal</div>
                  <div>
                    {{ $n(proposalInfo.sub_total, 'currency', 'en-CA') }}
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="me-4">HST 13%</div>
                  <div>
                    {{ $n(tax(proposalInfo.sub_total).toFixed(2), 'currency', 'en-CA') }}
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between fw-bold mb-4">
                  <span class="text-uppercase text-ls text-dark fs-9">Total:</span>
                  <strong class="fw-bold fs-5">
                    {{ $n(total(proposalInfo.sub_total).toFixed(2), 'currency', 'en-CA') }}
                  </strong>
                </div>
              </div>

              <div class="col-md-6 mb-6">
                <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Payment Schedule</h3>
                <div v-if="paymentSchedule.length" class="table-responsive">
                  <!--begin::Table-->
                  <table class="table">
                    <!--begin::Thead-->
                    <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                      <tr>
                        <th class="w-50px ps-0">Pct(%)</th>
                        <th>Milestone</th>
                        <th class="w-100px text-end">Amount($)</th>
                      </tr>
                    </thead>
                    <!--end::Thead-->
                    <!--begin::Tbody-->
                    <tbody>
                      <tr v-for="schedule in paymentSchedule" :key="schedule.id">
                        <td class="ps-0">{{ schedule.attributes.percentage }}%</td>
                        <td>{{ schedule.attributes.note }}</td>
                        <td class="text-end">
                          {{
                            $n(
                              priceFromPercentageWithHst(proposalInfo.sub_total, schedule.attributes.percentage),
                              'currency',
                              'en-CA'
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <!--end::Tbody-->
                  </table>
                  <!--end::Table-->
                </div>
                <px-placeholder v-else />
              </div>
            </div>

            <div class="bg-light rounded px-4 py-6 mb-8">
              <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Pricing & Payment Information</h3>
              <div class="format-no-space" v-html="normalizeString(proposalInfo.pricing_and_payment_information)" />
            </div>
            <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Acceptance</h3>
            <p>
              I hereby acknowledge that I have read this Proposal, understand and agree to its terms and conditions
              herein in their entirety, and accept that this document becomes my binding contract with Royal Stone.
            </p>
          </div>
          <div
            v-if="
              showTerms &&
              proposalInfo.terms_conditions_warranties !== emptyTermsAndConditions &&
              proposalInfo.terms_conditions_warranties
            "
            id="terms"
            class="mb-14"
          >
            <h2 class="fw-bold mb-4">Terms, Conditions, and Warranties</h2>
            <div
              class="scroll mh-400px py-4 px-2 border rounded"
              v-html="normalizeString(proposalInfo.terms_conditions_warranties)"
            />
          </div>

          <slot name="body-end" />
        </div>
        <!--end: Card Body-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProposalWizardSpecificationsMixin from '@/mixins/ProposalWizardSpecificationsMixin';

export default {
  props: {
    proposalId: {
      type: String,
    },
    proposalStatus: {
      type: String,
    },
    signedManualy: {
      type: Boolean,
    },
    currentProposalScopeName: {
      type: String,
    },
    isFullScreen: {
      type: Boolean,
    },
  },
  mixins: [ProposalWizardSpecificationsMixin],
  data() {
    return {
      itemId: this.$router.history.current.params.id,
      current: this.$router.history.current.name,
      scopeName: null,
      state: null,
      status: null,
      construction: null,
      estimateDate: null,
      number: null,
      manager: null,
      sideMenu: [],
      currentSideMenuItem: null,
      projectInfo: null,
      proposalInfo: null,
      usersInfo: null,
      equipment: [],
      inclusions: [],
      paymentSchedule: [],
      plantingSections: [],
      pricingSections: [],

      emptyTermsAndConditions: '"<p><br></p>"',

      initialToggles: [],
      checkedToggles: 0,

      isLoading: true,

      saveInProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      showEquipments: 'proposals/showEquipments',
      showInclusions: 'proposals/showInclusions',
      showIntroduction: 'proposals/showIntroduction',
      showPaymentDetails: 'proposals/showPaymentDetails',
      showPricing: 'proposals/showPricing',
      showPlantings: 'proposals/showPlantings',
      showSpecialNotes: 'proposals/showSpecialNotes',
      showSpecifications: 'proposals/showSpecifications',
      showTerms: 'proposals/showTerms',
      isFiberglassCustom: 'proposalWizard/isFiberglassCustom',
    }),
    currentToggles() {
      return [
        this.showEquipments,
        this.showInclusions,
        this.showIntroduction,
        this.showPaymentDetails,
        this.showPricing,
        this.showPlantings,
        this.showSpecialNotes,
        this.showSpecifications,
        this.showTerms,
      ];
    },
    compareToggles() {
      return JSON.stringify([...this.currentToggles]) === JSON.stringify([...this.initialToggles]);
    },
  },
  watch: {
    proposalId: async function (newVal) {
      await this.init(newVal || this.itemId);
    },
    proposalStatus: function (newVal) {
      this.status = newVal;

      if (this.status === 'Sent to Sign') {
        this.initialToggles = [true, true, true, true, true, true, true, true, true];
      }
    },
  },
  beforeMount() {
    this.itemId = this.$router.history.current.params.id;
    this.current = this.currentProposalScopeName || this.$router.history.current.name;
  },
  async mounted() {
    await this.init(this.proposalId || this.itemId);

    this.checkedToggles = this.toggledQty();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      single: 'proposals/single',
      actionUpdate: 'proposals/update',
    }),
    ...mapMutations({
      mutationShowEquipments: 'proposals/SET_SHOW_EQUIPMENTS',
      mutationShowInclusions: 'proposals/SET_SHOW_INCLUSIONS',
      mutationShowIntroduction: 'proposals/SET_SHOW_INTRODUCTION',
      mutationShowPaymentDetails: 'proposals/SET_SHOW_PAYMENT_DETAILS',
      mutationShowPricing: 'proposals/SET_SHOW_PRICING',
      mutationShowPlantings: 'proposals/SET_SHOW_PLANTINGS',
      mutationShowSpecialNotes: 'proposals/SET_SHOW_SPECIAL_NOTES',
      mutationShowSpecifications: 'proposals/SET_SHOW_SPECIFICATIONS',
      mutationShowTerms: 'proposals/SET_SHOW_TERMS',
    }),
    async init(id) {
      const fieldsArr = [
        'construction',
        'construction_details',
        'custom_shape_text',
        'deepend',
        'fiberglass_details',
        'equipment_items',
        'inclusions',
        'introduction',
        'length',
        'logo',
        'number',
        'payment_schedule_items',
        'pricing_and_payment_information',
        'pricing_sections',
        'planting_sections',
        'state',
        'status',
        'scope_type',
        'shallow',
        'shape',
        'shape_name',
        'show_equipments',
        'show_inclusions',
        'show_introduction',
        'show_payment_details',
        'show_plantings',
        'show_pricing',
        'show_special_notes',
        'show_specifications',
        'show_terms',
        'special_notes',
        'subject',
        'subject_first_name',
        'subject_last_name',
        'subject_address_line_1',
        'subject_postal',
        'subject_city',
        'subject_province',
        'subject_phone',
        'subject_email',
        'sub_total',
        'terms_conditions_warranties',
        'updated_at',
        'users',
        'version',
        'width',
      ];
      const single = await this.single({
        id,
        params: {
          fields: this.arrayToString(fieldsArr),
        },
      });
      const singleAttributes = single?.data?.data?.attributes;
      const menu = [];

      this.projectInfo = singleAttributes.subject.data.attributes;
      this.state = singleAttributes.state;
      this.status = singleAttributes.status;
      this.construction = singleAttributes.construction;
      this.number = singleAttributes.number;
      this.estimateDate = singleAttributes.updated_at;
      this.manager = `${singleAttributes.subject.data.attributes.manager.data.attributes.first_name} ${singleAttributes.subject.data.attributes.manager.data.attributes.last_name}`;
      this.usersInfo = singleAttributes.users.data.attributes;
      this.equipment = singleAttributes.equipment_items.data;
      this.inclusions = singleAttributes.inclusions.data;
      this.paymentSchedule = singleAttributes.payment_schedule_items.data;
      this.pricingSections = singleAttributes.pricing_sections.data;
      this.plantingSections = singleAttributes.planting_sections.data;

      delete singleAttributes.subject;
      delete singleAttributes.users;
      delete singleAttributes.equipment_items;
      delete singleAttributes.inclusions;
      delete singleAttributes.payment_schedule_items;
      delete singleAttributes.pricing_sections;
      delete singleAttributes.planting_sections;

      this.proposalInfo = singleAttributes;
      this.scopeName = this.scopeNameByParam(this.proposalInfo.scope_type);

      switch (singleAttributes.scope_type) {
        case 'Swimming Pool':
          menu.push({
            title: 'Introduction',
            hash: 'introduction',
            control: this.showIntroduction,
            onChange: this.onShowIntroductionChange,
          });
          menu.push({
            title: 'Specification',
            hash: 'specifications',
            control: this.showSpecifications,
            onChange: this.onShowSpecificationsChange,
          });
          if (this.construction !== 'Renovate') {
            menu.push({
              title: 'Equipment',
              hash: 'equipment',
              control: this.showEquipments,
              onChange: this.onShowEquipmentsChange,
            });
          }
          if (this.checkIfHasPricingItems(this.pricingSections, 'pricing_items')) {
            menu.push({
              title: 'Pricing',
              hash: 'pricing',
              control: this.showPricing,
              onChange: this.onShowPricingChange,
            });
          }
          if (singleAttributes.special_notes) {
            menu.push({
              title: 'Notes',
              hash: 'notes',
              control: this.showSpecialNotes,
              onChange: this.onShowSpecialNotesChange,
            });
          }
          menu.push({
            title: 'Considerations',
            hash: 'considerations',
            control: this.showInclusions,
            onChange: this.onShowInclusionsChange,
          });
          menu.push({
            title: 'Payment Details',
            hash: 'payment-details',
            control: this.showPaymentDetails,
            onChange: this.onShowPaymentDetailsChange,
          });
          if (
            this.proposalInfo.terms_conditions_warranties !== this.emptyTermsAndConditions &&
            this.proposalInfo.terms_conditions_warranties
          ) {
            menu.push({
              title: 'Terms, Conditions, and Warranties',
              hash: 'terms',
              control: this.showTerms,
              onChange: this.onShowTermsChange,
            });
          }

          this.sideMenu = menu;
          break;
        case 'Woodworking':
          menu.push({
            title: 'Introduction',
            hash: 'introduction',
            control: this.showIntroduction,
            onChange: this.onShowIntroductionChange,
          });
          if (this.checkIfHasPricingItems(this.pricingSections, 'pricing_items')) {
            menu.push({
              title: 'Pricing',
              hash: 'pricing',
              control: this.showPricing,
              onChange: this.onShowPricingChange,
            });
          }
          if (singleAttributes.special_notes) {
            menu.push({
              title: 'Notes',
              hash: 'notes',
              control: this.showSpecialNotes,
              onChange: this.onShowSpecialNotesChange,
            });
          }
          menu.push({
            title: 'Considerations',
            hash: 'considerations',
            control: this.showInclusions,
            onChange: this.onShowInclusionsChange,
          });
          menu.push({
            title: 'Payment Details',
            hash: 'payment-details',
            control: this.showPaymentDetails,
            onChange: this.onShowPaymentDetailsChange,
          });
          if (
            this.proposalInfo.terms_conditions_warranties !== this.emptyTermsAndConditions &&
            this.proposalInfo.terms_conditions_warranties
          ) {
            menu.push({
              title: 'Terms, Conditions, and Warranties',
              hash: 'terms',
              control: this.showTerms,
              onChange: this.onShowTermsChange,
            });
          }

          this.sideMenu = menu;
          break;
        case 'Landscaping':
          menu.push({
            title: 'Introduction',
            hash: 'introduction',
            control: this.showIntroduction,
            onChange: this.onShowIntroductionChange,
          });
          if (this.checkIfHasPricingItems(this.pricingSections, 'pricing_items')) {
            menu.push({
              title: 'Pricing',
              hash: 'pricing',
              control: this.showPricing,
              onChange: this.onShowPricingChange,
            });
          }
          if (this.checkIfHasPricingItems(this.plantingSections, 'planting_items')) {
            menu.push({
              title: 'Planting',
              hash: 'planting',
              control: this.showPlantings,
              onChange: this.onShowPlantingsChange,
            });
          }
          if (singleAttributes.special_notes) {
            menu.push({
              title: 'Notes',
              hash: 'notes',
              control: this.showSpecialNotes,
              onChange: this.onShowSpecialNotesChange,
            });
          }
          menu.push({
            title: 'Considerations',
            hash: 'considerations',
            control: this.showInclusions,
            onChange: this.onShowInclusionsChange,
          });
          menu.push({
            title: 'Payment Details',
            hash: 'payment-details',
            control: this.showPaymentDetails,
            onChange: this.onShowPaymentDetailsChange,
          });
          if (
            this.proposalInfo.terms_conditions_warranties !== this.emptyTermsAndConditions &&
            this.proposalInfo.terms_conditions_warranties
          ) {
            menu.push({
              title: 'Terms, Conditions, and Warranties',
              hash: 'terms',
              control: this.showTerms,
              onChange: this.onShowTermsChange,
            });
          }

          this.sideMenu = menu;
          break;
        default:
          break;
      }

      this.initialToggles = [
        this.showEquipments,
        this.showInclusions,
        this.showIntroduction,
        this.showPaymentDetails,
        this.showPricing,
        this.showPlantings,
        this.showSpecialNotes,
        this.showSpecifications,
        this.showTerms,
      ];

      this.currentSideMenuItem = this.sideMenu[1].hash;
    },
    checkIfHasPricingItems(items, fieldName) {
      return !!items.find((el) => el.attributes[fieldName]?.data?.length > 0);
    },
    totalPricingItems(items) {
      let totalPrice = 0;

      items.map((el) => {
        if (el.attributes.price_format === 'Default') {
          const { quantity, waste, price } = el.attributes;
          totalPrice += (quantity + Math.ceil((quantity / 100) * waste)) * price;
        }
      });

      return totalPrice;
    },
    totalPlantingItems(items) {
      let totalPrice = 0;

      items.map((el) => {
        if (el.attributes.price_format === 'Default') {
          const { cost, multiplier, quantity } = el.attributes;
          totalPrice += cost * multiplier * quantity;
        }
      });

      return totalPrice;
    },
    tax(val) {
      return (val / 100) * 13;
    },
    total(val) {
      return val + this.tax(val);
    },
    totalPercentage(items) {
      let sum = 0;

      for (let item of items) {
        sum += parseInt(item?.attributes?.percentage) || 0;
      }

      return sum;
    },
    priceFromPercentage(subTotal, percentage) {
      return ((subTotal / 100) * percentage).toFixed(2);
    },
    priceFromPercentageWithHst(subTotal, percentage) {
      return (((subTotal + this.tax(subTotal)) / 100) * percentage).toFixed(2);
    },
    onShowIntroductionChange() {
      this.mutationShowIntroduction(!this.showIntroduction);

      this.checkedToggles = this.toggledQty();
    },
    onShowSpecificationsChange() {
      this.mutationShowSpecifications(!this.showSpecifications);

      this.checkedToggles = this.toggledQty();
    },
    onShowEquipmentsChange() {
      this.mutationShowEquipments(!this.showEquipments);

      this.checkedToggles = this.toggledQty();
    },
    onShowPricingChange() {
      this.mutationShowPricing(!this.showPricing);

      this.checkedToggles = this.toggledQty();
    },
    onShowPlantingsChange() {
      this.mutationShowPlantings(!this.showPlantings);

      this.checkedToggles = this.toggledQty();
    },
    onShowSpecialNotesChange() {
      this.mutationShowSpecialNotes(!this.showSpecialNotes);

      this.checkedToggles = this.toggledQty();
    },
    onShowInclusionsChange() {
      this.mutationShowInclusions(!this.showInclusions);

      this.checkedToggles = this.toggledQty();
    },
    onShowPaymentDetailsChange() {
      this.mutationShowPaymentDetails(!this.showPaymentDetails);

      this.checkedToggles = this.toggledQty();
    },
    onShowTermsChange() {
      this.mutationShowTerms(!this.showTerms);

      this.checkedToggles = this.toggledQty();
    },

    toggledQty() {
      return document.querySelectorAll('.side-menu-checkbox:checked').length;
    },

    async onSave() {
      this.saveInProcess = true;

      await this.actionUpdate({
        id: this.itemId,
        show_equipments: this.showEquipments,
        show_inclusions: this.showInclusions,
        show_introduction: this.showIntroduction,
        show_payment_details: this.showPaymentDetails,
        show_pricing: this.showPricing,
        show_special_notes: this.showSpecialNotes,
        show_specifications: this.showSpecifications,
        show_terms: this.showTerms,
      });

      this.saveInProcess = false;

      this.initialToggles = [
        this.showEquipments,
        this.showInclusions,
        this.showIntroduction,
        this.showPaymentDetails,
        this.showPricing,
        this.showPlantings,
        this.showSpecialNotes,
        this.showSpecifications,
        this.showTerms,
      ];
    },
    onCancel() {
      this.sideMenu = this.sideMenu.map((el) => {
        if (el.hash === 'equipment') el.control = this.initialToggles[0];
        if (el.hash === 'considerations') el.control = this.initialToggles[1];
        if (el.hash === 'introduction') el.control = this.initialToggles[2];
        if (el.hash === 'payment-details') el.control = this.initialToggles[3];
        if (el.hash === 'pricing') el.control = this.initialToggles[4];
        if (el.hash === 'planting') el.control = this.initialToggles[5];
        if (el.hash === 'notes') el.control = this.initialToggles[6];
        if (el.hash === 'specifications') el.control = this.initialToggles[7];
        if (el.hash === 'terms') el.control = this.initialToggles[8];

        return el;
      });

      this.mutationShowEquipments(this.initialToggles[0]);
      this.mutationShowInclusions(this.initialToggles[1]);
      this.mutationShowIntroduction(this.initialToggles[2]);
      this.mutationShowPaymentDetails(this.initialToggles[3]);
      this.mutationShowPricing(this.initialToggles[4]);
      this.mutationShowPlantings(this.initialToggles[5]);
      this.mutationShowSpecialNotes(this.initialToggles[6]);
      this.mutationShowSpecifications(this.initialToggles[7]);
      this.mutationShowTerms(this.initialToggles[8]);
    },
  },
};
</script>

<style scoped>
.img-blend-multiply {
  mix-blend-mode: multiply;
}
</style>
