import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      proposalAttributes: null,
      constructionDetailsTemplates: null,
      isManualSize: false,
      hasErrors: false,
      form: {
        construction: null,
        shape: null,
        width: null,
        length: null,
        size_category: null,
        shallow: null,
        depend: null,
        construction_details: null,
      },
    };
  },
  async mounted() {
    const fieldsArr = [
      'construction',
      'construction_details',
      'deepend',
      'length',
      'shallow',
      'shape',
      'size_category',
      'width',
    ];
    const _constructionDetailsTemplates = {};
    const proposal = await this.getSingleProposal({
      id: this.itemId,
      params: {
        fields: this.arrayToString(fieldsArr),
      },
    });
    await this.constructionDetailsTemplatesInit();
    this.constructionDetailsTemplatesItems.data.map((el) => {
      _constructionDetailsTemplates[el.attributes.construction_type] = el.attributes;
    });

    this.proposalAttributes = proposal.data.data.attributes;
    this.constructionDetailsTemplates = _constructionDetailsTemplates;

    this.form = {
      construction: this.proposalAttributes.construction || null,
      shape: this.proposalAttributes.shape || null,
      width: this.proposalAttributes.width || '15',
      length: this.proposalAttributes.length || '36',
      size_category: this.proposalAttributes.size_category || 'Small',
      shallow: this.proposalAttributes.shallow || '3',
      depend: this.proposalAttributes.deepend || '6',
      construction_details:
        this.proposalAttributes.construction_details ||
        (this.constructionDetailsTemplates[this.proposalAttributes.construction]
          ? this.constructionDetailsTemplates[this.proposalAttributes.construction].text
          : ''),
    };
    this.hasErrors = this.$v.form.$anyError;
  },
  validations: {
    form: {
      construction: { required },
      shape: { required },
      width: { required },
      length: { required },
      size_category: { required },
      shallow: { required },
      depend: { required },
      construction_details: { required },
    },
  },
  computed: {
    ...mapGetters({
      constructionDetailsTemplatesItems: 'constructionDetailsTemplates/items',
    }),
    constructions() {
      return [
        { title: 'Vinyl', description: 'Vinyl lined watershape with steel walls and a 32MPa concrete floor' },
        {
          title: 'Concrete',
          description: 'Concrete pool with 12" walls and 12" floor, reinforced with 15mm steel rebar',
        },
        {
          title: 'Fiberglass',
          description: 'Fiberglass shell composed of interwoven glass threads covered with a polyester resin',
        },
        { title: 'Renovate', description: 'Pool renovation as per the specifications described in this proposal' },
      ];
    },
    sizeCategories() {
      return ['Small', 'Medium', 'Large', 'Extra Large'];
    },
    shapes() {
      return ['Rectangular with square concerns', '1'];
    },
  },
  methods: {
    ...mapActions({
      getSingleProposal: 'proposals/single',
      constructionDetailsTemplatesInit: 'constructionDetailsTemplates/index',
    }),
    normalizeString(str) {
      if (str) {
        return str.startsWith('"') ? JSON.parse(str) : str;
      }

      if (str === null) {
        return '';
      }
    },
    constructionDetailsTemplate(val) {
      switch (val) {
        case 'Vinyl':
          return this.form.construction === this.proposalAttributes.construction
            ? this.proposalAttributes.construction_details
            : 'The structural components of the watershape include 14 gauge Z720 (G235) galvanized steel walls, double riveted for strength and durability. Walls reinforced with 24” standard ‘A-frame’ supports and 12” deck supports every 4 feet and encapsulated with at least 8” of concrete. Floor poured using 4000psi ready mix supplied concrete for consistency and strength. Vermiculite grout smooth coat applied on top of concrete floor for flexibility and to eliminate liner abrasion.\n A pro-rated lifetime warrantied vinyl liner in your choice of colour and pattern will be custom measured and manufactured once the pool shell is complete. This ensures an exact fit and accommodates for any changes in pool dig specifications. The liner material is impregnated with algaecide to provide algae resistance and features 3/4” lap seams that are factory welded for the strongest, most durable hold available.';
        case 'Concrete':
          return this.form.construction === this.proposalAttributes.construction
            ? this.proposalAttributes.construction_details
            : 'The structure of the watershape includes a pneumatically applied 35 MPa shotcrete mix shell with 12" thick concrete floor and 12" thick concrete walls reinforced with 15mm rebar on 10"-12" centres vertically and horizontally. Double steel will be used horizontaly in the top 2\' of the pool wall and as required. The pool shell is installed on a 6" thick layer of 3/4" clear gravel to promote proper drainage. The pool shell includes shotcrete stairs/benches up to a maximum of 40 sq.ft.';
        case 'Fiberglass':
          return this.form.construction === this.proposalAttributes.construction
            ? this.proposalAttributes.construction_details
            : 'Latham fiberglass pools are in-mold, handcrafted pools that arrive at your home ready to be installed in your yard. The fiberglass is created from millions of interwoven glass threads with vertical stiffeners covered with layers of vinyl ester resin and strong bond beam construction. It’s an incredibly sturdy material that’s ideal for an in-ground pool. The fiberglass shell is protected by a manufacturers Limited Lifetime Structural and Surface Warranty. All Latham Pool Products are proudly manufactured in our multiple high-tech facilities throughout the U.S. and Canada.\n The Gelcoat exibits strong antimicrobial behaviour to inhibit the growth of bacteria at the gelcoat surface. This factor dramatically reduces the chance of algae growing in your pool. The shell is available in many colours, from cool blues to warm sand tones, pearlescent to prismatic, there’s a pool colour and style for every home.';
        case 'Renovate':
          return this.form.construction === this.proposalAttributes.construction
            ? this.proposalAttributes.construction_details
            : 'We want you to enjoy your watershape! We will renovate the following:';
        default:
          return '';
      }
    },
    sizeAccordingToWidthAndLength(w, l) {
      if (w * l > 800) {
        return 'Extra Large';
      } else if (w * l > 550) {
        return 'Large';
      } else if (w * l > 400) {
        return 'Medium';
      } else {
        return 'Small';
      }
    },
  },
};
